import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import CallToActionContact from "../components/CallToActionContact"

export const query = graphql`
  query ClientResultsQuery($uid: String) {
    prismic {
      allClient_results(uid: $uid, sortBy: meta_lastPublicationDate_ASC) {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            feature_image
            main_content
            short_description
            title
            _meta {
              uid
              lastPublicationDate
            }
          }
        }
      }
    }
  }
`

const HeroImage = styled("div")`
  height: 600px;
  width: 100%;
  background-color: #454545;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    max-width: 1920px;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 480px) {
    height: 400px;
  }

  .hero-text {
    width: 100%;
    max-width: 900px;
    flex: 0 0 auto;
    color: #ffffff;
    z-index: 1;
    position: absolute;
    bottom: 0;
    transform: translateY(-2rem);

    @media screen and (max-width: 900px) {
      padding: 1rem;
    }

    h1 {
      font-size: calc(36px + (55 - 36) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

const StyledContentSection = styled(ContentSection)`
  .inner-container {
    max-width: 900px;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
    margin-top: 0;
    font-weight: 700;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  }

  img {
    width: 100%;
    max-width: 900px;
    height: auto;
  }

  .embed {
    iframe {
      width: 100%;
      height: 500px;

      @media screen and (max-width: 480px) {
        height: 275px;
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allClient_results.edges.slice(0, 1).pop()
  if (!doc) return null
  const regularLinks = [
    {
      url: "/client-results",
      type: "internal",
      label: "Client Results",
    },
    {
      url: "/contact",
      type: "internal",
      label: "Let's Get Started",
    },
  ]

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.title &&
      doc.node.title[0] &&
      doc.node.title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    (doc.node &&
      doc.node.short_description &&
      doc.node.short_description[0] &&
      doc.node.short_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={doc.node.feature_image.url}
      />
      <TopSubNav title="Client Case Study" regularLinks={regularLinks} />
      {doc.node.feature_image && doc.node.feature_image.url && (
        <HeroImage imageUrl={doc.node.feature_image.url}>
          <div className="hero-text">
            {doc.node.seo_h1 && doc.node.seo_h1[0] && doc.node.seo_h1[0].text
              ? RichText.render(doc.node.seo_h1)
              : RichText.render(doc.node.title)}
          </div>
        </HeroImage>
      )}
      <StyledContentSection>
        <div className="inner-container">
          {(!doc.node.feature_image || !doc.node.feature_image.url) && (
            <>
              {doc.node.seo_h1 && doc.node.seo_h1[0] && doc.node.seo_h1[0].text
                ? RichText.render(doc.node.seo_h1)
                : RichText.render(doc.node.title)}
            </>
          )}
          {RichText.render(doc.node.main_content)}
        </div>
      </StyledContentSection>
      <CallToActionContact />
    </Layout>
  )
}
